import React from 'react';
import styled from 'styled-components';
import Rte from './Rte';
import { sizeAsBase, sizeAsMax } from '../styles/utils';
import { media } from '../styles';
import { h2, author } from '../styles/typography';
import GatsbyImage from 'gatsby-image';

const StyledNews = styled.div`
    padding: ${props => props.theme.spacing.s80} ${sizeAsBase(5)};
    box-shadow: inset 0 1px 0 0 ${props => props.theme.colors.border};

    ${media.large`
        padding-left: ${sizeAsMax(5)};
        padding-right: ${sizeAsMax(5)};
    `};
`;

const StyledTitle = styled.h2`
    ${h2};
`;

const StyledInfos = styled.p`
    ${author};
    margin-top: ${props => props.theme.spacing.s20};
    margin-bottom: ${props => props.theme.spacing.s60};
`;

const StyleFigure = styled.figure`
    margin-bottom: ${props => props.theme.spacing.s40};

    figcaption {
        word-break: break-all;
        margin-top: ${props => props.theme.spacing.s10};
        font-size: ${props => props.theme.fontSize.xsmall};
        line-height: ${props => props.theme.lineHeight.xsmall};
    }
`;

const NewsFull = ({ id, date, data }) => {
    const { title, author, content, cover, cover_legend } = data;
    return (
        <StyledNews key={id}>
            {cover.local && cover.local.sharp && (
                <StyleFigure>
                    <GatsbyImage fluid={cover.local.sharp.image} />
                    <figcaption>{cover_legend}</figcaption>
                </StyleFigure>
            )}
            <StyledTitle>{title.text}</StyledTitle>
            <StyledInfos>
                by {author}, {date}
            </StyledInfos>
            <Rte dangerouslySetInnerHTML={{ __html: content.html }} />
        </StyledNews>
    );
};

export default NewsFull;
