import React from 'react';
import styled from 'styled-components';
import { graphql, navigate } from 'gatsby';
import NewsFull from '../components/NewsFull';
import Row, { rowShadowGreyCSS } from '../components/Row';
import { sizeAsBase, sizeAsMax } from '../styles/utils';
import { media } from '../styles';
import { h1, h3 } from '../styles/typography';
import ReactPaginate from 'react-paginate';
import BorderSeparator from '../components/BorderSeparator';
import { linkReset } from '../styles/others';
import { Helmet } from 'react-helmet';

const StyledPage = styled.div`
    background: ${props => props.theme.colors.red};
`;

const StyledRow = styled(Row)`
    position: relative;
    overflow: initial;
    ${rowShadowGreyCSS};
`;

const StyledTitle = styled.h1`
    ${h1};
    position: sticky;
    top: ${props => props.theme.sizes.headerHeight}px;
    padding-left: ${props => props.theme.spacing.s40};
    padding-top: ${props => props.theme.spacing.s70};
    font-weight: bold;
    color: ${props => props.theme.colors.white};
`;

const StyledLeft = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: ${sizeAsBase(27)};

    ${media.mobile`
        position: relative;
        top: auto;
        left: auto;
        padding-left: ${sizeAsBase(2)};
        padding-top: ${props => props.theme.spacing.s20};
        margin-bottom: ${props => props.theme.spacing.s80};
    `}
`;

const StyledRight = styled.div`
    position: relative;
    margin-left: ${sizeAsBase(27)};
    margin-right: ${sizeAsBase(-3)};
    background: ${props => props.theme.colors.white};

    ${media.large`
        margin-right: ${sizeAsMax(-3)};
        margin-left: ${sizeAsMax(27)};
    `}

    ${media.mobile`
        margin-left: ${sizeAsBase(-3)};

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: ${sizeAsBase(3)};
            margin-left: -1px;
            width: 1px;
            background: ${props => props.theme.colors.grey};
        }

        &:after {
            left: auto;
            margin-left: auto;
            margin-right: -1px;
            right: ${sizeAsBase(3)};
        }
    `}
`;

const StyledPagination = styled.div`
    ${h3};
    font-weight: normal;
    padding: ${props => props.theme.spacing.s40} ${sizeAsBase(5)};

    ${media.large`
        padding-left: ${sizeAsMax(5)};
        padding-right: ${sizeAsMax(5)};
    `};

    ul {
        display: flex;
    }

    a {
        ${linkReset};
        color: ${props => props.theme.colors.font};
    }

    .page {
        opacity: 0.6;
        padding: 0 0.25em;

        &.is-active {
            opacity: 1;
        }
    }

    .page-next {
        margin-left: auto;
        &.disabled {
            opacity: 0.6;
        }
    }
`;

const News = ({ data, pageContext }) => {
    const { numPages, currentPage, lang } = pageContext;
    const { title, seo_title, seo_description } = data.page.data;
    const handlePageChange = ({ selected }) => {
        const i = selected + 1;
        navigate(`/${lang}/${data.page.uid}${i > 1 ? `/${i}/` : '/'}`);
    };

    return (
        <StyledPage>
            <Helmet>
                <title>{seo_title}</title>{' '}
                {seo_description && <meta name="description" content={seo_description} />}
            </Helmet>
            <StyledRow>
                <StyledLeft>
                    <StyledTitle>{title.text}</StyledTitle>
                </StyledLeft>
                <StyledRight>
                    {data.news.edges.map(({ node }) => (
                        <NewsFull key={node.id} {...node} />
                    ))}
                    <BorderSeparator />
                    <StyledPagination>
                        <ReactPaginate
                            previousLabel={null}
                            nextLabel="—› Page suivante"
                            breakLabel={'...'}
                            breakClassName={'break'}
                            nextClassName={'page-next'}
                            pageClassName={'page'}
                            pageCount={numPages}
                            forcePage={currentPage - 1}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChange}
                            subContainerClassName={'subcontainer'}
                            activeClassName={'is-active'}
                            hrefBuilder={i => `/${lang}/${data.page.uid}${i > 1 ? `/${i}/` : '/'}`}
                        />
                    </StyledPagination>
                </StyledRight>
            </StyledRow>
        </StyledPage>
    );
};

export default News;

export const pageQuery = graphql`
    query newsListQuery($skip: Int!, $limit: Int!, $lang: String!) {
        page: prismicPageNews(lang: { eq: $lang }) {
            uid
            data {
                seo_title
                seo_description
                title {
                    text
                }
            }
        }
        news: allPrismicNewsPost(
            filter: { lang: { eq: $lang } }
            sort: { fields: [first_publication_date], order: DESC }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    id: prismicId
                    date: first_publication_date(formatString: "MMM YYYY", locale: $lang)
                    data {
                        cover {
                            local: localFile {
                                ...sharp700xauto
                            }
                        }
                        cover_legend
                        title {
                            text
                        }
                        author
                        content {
                            html
                        }
                    }
                }
            }
        }
    }
`;
